import React from "react";
import { Link } from "react-router-dom";

const Notfoundpage = () => {
  return (
    <section className="relative pt-[120px] pb-0  z-20">
      <div className="wraper">
        <div className="grid grid-cols-12">
          <div className="col-span-1"></div>
          <div className="col-span-10 md:col-span-12">
            <div className="max-w-[440px] mx-auto text-center mb-[50px]">
              <h2 className="text-[35px] col:text-[25px] text-[#282e3f] font-base-font font-bold mb-[20px]">
                Algo deu errado
              </h2>
              <p className="text-[#666] text-[16px] leading-[22px]">
                Não encontramos a página que você estava procurando
              </p>
              <div className="btns">
                <div className="btn-style">
                  <Link
                    to="/"
                    style={{
                        marginTop: "50px",
                    }}
                    className="bg-[#c0b596] relative cursor-pointer text-[16px]
                             font-semibold text-white px-[38px] py-[12px]  capitalize inline-block mt-[6px] 
                             transition ease-in-out duration-300 hover:bg-[#d4c291]
                            col:mb-[5px] col:mt-[15px] col:text-[15px] col:px-[18px] col:py-[8px] 
                            before:absolute before:content-[''] before:w-[85px] before:h-[2px] before:left-[-60px]
                             before:top-[50%]  before:bg-white before:transform before:translate-y-[-50%] 
                             before:transition-all hover:before:left-[-95px] md:before:hidden"
                  >
                    Página Inicial
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Notfoundpage;
