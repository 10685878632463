import React from 'react';
import ContactForm from '../ContactForm/ContactForm'


const Contactpage = () => {

    return (

        <section className="relative pt-[120px] pb-0  z-20">
            <div className="wraper">
                <div className="grid grid-cols-12">
                    <div className="col-span-1"></div>
                    <div className="col-span-10 md:col-span-12">
                        <div className="pb-[100px] sm:pb-[60px]">
                            <div className="grid grid-cols-12 gap-3">
                                <div className="col-span-4 md:col-span-6 sm:col-span-12 md:mb-7">
                                    <div className="text-center p-[40px]  shadow-[0_5px_15px_0_rgba(62,65,159,0.1)]">
                                        <div className="w-[85px] h-[85px] bg-[#f5f5f5] leading-[85px] text-center mx-auto rounded-[50%]">
                                            <div className="icon">
                                                <i className="text-[35px] leading-[85px] text-[#282e3f] fa fa-map-marker" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <h2 className="text-[25px] text-[#282e3f] font-medium my-[20px]">Atendemos em</h2>
                                        <p className="text-[#666] text-[16px] leading-[22px]">São José do Rio Preto e região</p>
                                    </div>
                                </div>
                                <div className="col-span-4 md:col-span-6 sm:col-span-12 md:mb-7">
                                    <div className="text-center p-[40px]  shadow-[0_5px_15px_0_rgba(62,65,159,0.1)]">
                                        <div className="w-[85px] h-[85px] bg-[#f5f5f5] leading-[85px] text-center mx-auto rounded-[50%]">
                                            <div className="icon">
                                                <i className="text-[35px] leading-[85px] text-[#282e3f] fa fa-envelope" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <h2 className="text-[25px] text-[#282e3f] font-medium my-[20px]">E-mail</h2>
                                        <p className="text-[#666] text-[16px] leading-[22px]"><a href="mailto:julia@jferraretoadvocacia.com.br?subject=Consulta Jurídica">julia@jferraretoadvocacia.com.br</a></p>
                                    </div>
                                </div>
                                <div className="col-span-4 md:col-span-6 sm:col-span-12 md:mb-7">
                                    <div className="text-center p-[40px]  shadow-[0_5px_15px_0_rgba(62,65,159,0.1)]">
                                        <div className="w-[85px] h-[85px] bg-[#f5f5f5] leading-[85px] text-center mx-auto rounded-[50%]">
                                            <div className="icon">
                                                <i className="text-[35px] leading-[85px] text-[#282e3f] fa fa-phone" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <h2 className="text-[25px] text-[#282e3f] font-medium my-[20px]">Telefone</h2>
                                        <p className="text-[#666] text-[16px] leading-[22px]"><a href="https://wa.me/5517997254413?text=Olá!%20Gostaria%20de%20orientação%20jurídica." rel="noreferrer" target="_blank">(17) 99725 - 4413</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="max-w-[440px] mx-auto text-center mb-[50px]">
                            <h2 className="text-[35px] col:text-[25px] text-[#282e3f] font-base-font font-bold mb-[20px]">Alguma dúvida?</h2>
                            <p className="text-[#666] text-[16px] leading-[22px]">Se você tiver alguma dúvida ou precisar de mais informações, não hesite em entrar em contato conosco. Estamos aqui para ajudar e fornecer a assistência jurídica necessária para garantir a melhor experiência possível.</p>
                        </div>
                        <div className="p-[50px] bg-white mb-[-125px] relative z-10 shadow-[0_8px_17px_0_rgba(0,0,0,20%),0_6px_20px_0_rgba(0,0,0,19%)] sm:p-7 sm:pt-[50px]">
                             <ContactForm />
                        </div>
                    </div>
                    <div className="col-span-1"></div>
                </div>
            </div>
            <div className="wpo-contact-map-section ">
                <h2 className="hidden">Mapa</h2>
                <div className="h-[550px]">
                <iframe title='Mapa de São José do Rio Preto' className="w-full h-full border-0 outline-0 grayscale" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d119339.79754903947!2d-49.382355499999996!3d-20.816813949999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94bdad614c2df789%3A0x8f2fb0f070642c09!2zU8OjbyBKb3PDqSBkbyBSaW8gUHJldG8sIFNQ!5e0!3m2!1spt-BR!2sbr!4v1703646652959!5m2!1spt-BR!2sbr" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </section>
    )

}

export default Contactpage;
