import React from "react";
import { Link } from "react-router-dom";
import Practices from "../../api/Practices";
import cImg from "../../images/practice/areas-atuacao.jpg";

const PracticeHome = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section
      className="relative bg-no-repeat bg-cover bg-center z-10 pt-[95px] pb-[95px] md:pb-[75px]
          before:absolute before:left-0 before:top-0 before:w-full before:h-full before:bg-[rgba(21,26,48,.66)]  before:-z-10 "
      style={{ backgroundImage: `url(${cImg})` }}
    >
      <div className="wraper">
        <div className="col-span-12 text-center">
          <div className="mb-[60px]">
            <span className="text-[16px] text-[#ada282]">Áreas de Atuação</span>
            <h2 className=" text-[36px] md:text-[26px] font-medium text-white pb-[20px] relative before:absolute before:content-[''] before:left-[50%] before:bottom-0 before:transform before:-translate-x-1/2 before:w-[60px] before:h-[3px] before:bg-[#c0b596]">
              Como podemos te ajudar
            </h2>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-5 flex flex-col">
          {Practices.map((practice, Pitem) => {
            const blockClass =
              practice.Id === "7"
                ? "col-span-12 md:col-span-12 sm:col-span-12 flex items-stretch"
                : "col-span-4 md:col-span-6 sm:col-span-12 flex items-stretch";
            return (
              <div className={blockClass} key={Pitem}>
                <Link
                  className="transition-all hover:text-[#c0b596]"
                  onClick={ClickHandler}
                  to={`/area-de-atuacao/${practice.slug}`}
                >
                  <div className="group flex flex-col items-center sm:mx-[80px] col:mx-0 bg-[rgba(192,181,150,.30)] p-[20px] lg:p-[10px]">
                    <div className="pl-[24px] flex flex-col justify-between h-full">
                      <h3
                        className="text-white text-[24px] col:text-[20px] font-medium pb-[10px] relative mb-[10px]
                        before:absolute before:content-[''] before:left-0 before:bottom-0 before:w-[30px] before:h-[2px] before:bg-[#c0b596]"
                      >
                        {practice.sTitle}
                      </h3>
                      <p className="text-white text-[16px] font-normal">
                        {practice.description}
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default PracticeHome;
