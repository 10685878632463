import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";

const ContactForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [forms, setForms] = useState({
    name: "",
    email: "",
    subject: "Área Jurídica",
    phone: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!forms.name || forms.name.trim() === "") {
      newErrors.name = "O campo Nome é obrigatório";
    }

    if (forms.name.length <= 5) {
      newErrors.name = "Insira seu nome completo";
    }

    if (!forms.phone) {
      newErrors.phone = "Por favor, insira um número de telefone válido";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!forms.email || !emailRegex.test(forms.email)) {
      newErrors.email = "Por favor, insira um endereço de e-mail válido";
    }

    if (forms.subject === "Área Jurídica") {
      newErrors.subject = "Por favor, selecione uma área jurídica";
    }

    if (!forms.message || forms.message.trim() === "") {
      newErrors.message = "O campo Mensagem é obrigatório";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length > 0 ? newErrors : null;
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();

    if (!validationErrors) {
      setIsLoading(true);
      const mailOptions = {
        to: "julia@jferraretoadvocacia.com.br",
        subject:`Mensagem do Site - Área de Atuação - ${forms.subject}`,
        replyTo: forms.email,
        from: 'jf',
        html: `
          <!DOCTYPE html>
          <html lang="en">
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
          </head>
          <body>
            <p><strong>Nome:</strong> ${forms.name}</p>
            <p><strong>Telefone:</strong> ${forms.phone}</p>
            <p><strong>E-mail:</strong> ${forms.email}</p>
            <p><strong>Mensagem:</strong> ${forms.message}</p>
          </body>
          </html>
        `,
      };

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_DOMAIN_URL}/send-email`,
          mailOptions
        );

        if (response.data.success) {
          setForms({
            name: "",
            email: "",
            subject: "Área Jurídica",
            phone: "",
            message: "",
          });

          toast.success("Sua mensagem foi enviada!", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error("Ocorreu um erro ao enviar sua mensagem!", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        toast.error("Ocorreu um erro ao enviar sua mensagem!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <form
      method="post"
      className="contact-validation-active mx-[-15px] overflow-hidden grid grid-cols-12 gap-3"
      id="contact-form-main"
      onSubmit={(e) => submitHandler(e)}
    >
      <div className="col-span-6 md:col-span-6 sm:col-span-12 float-left mx-[15px] mb-[10px] col:float-none col:w-[calc(100%-25px)]">
        <input
          className="form-control w-full bg-transparent h-[50px] border border-[#ebebeb] text-[#666] transition-all pl-[25px] focus:outline-0 focus:shadow-none focus:border-[#c0b596] focus:bg-transparent"
          value={forms.name}
          type="text"
          name="name"
          onBlur={(e) => changeHandler(e)}
          onChange={(e) => changeHandler(e)}
          required
          placeholder="Nome completo"
          disabled={isLoading}
        />
        {errors.name && (
          <div className="text-red-500 mt-2 text-sm">{errors.name}</div>
        )}
      </div>
      <div className="col-span-6 md:col-span-6 sm:col-span-12 float-left mx-[15px] mb-[10px] col:float-none col:w-[calc(100%-25px)]">
        <input
          className="form-control  w-full bg-transparent h-[50px] border border-[#ebebeb] text-[#666] transition-all pl-[25px] focus:outline-0 focus:shadow-none focus:border-[#c0b596] focus:bg-transparent"
          value={forms.email}
          type="email"
          name="email"
          onBlur={(e) => changeHandler(e)}
          onChange={(e) => changeHandler(e)}
          required
          placeholder="E-mail"
          disabled={isLoading}
        />
        {errors.email && (
          <div className="text-red-500 mt-2 text-sm">{errors.email}</div>
        )}
      </div>
      <div className="col-span-6 md:col-span-6 sm:col-span-12 float-left mx-[15px] mb-[10px] col:float-none col:w-[calc(100%-25px)]">
        <input
          className="form-control  w-full bg-transparent h-[50px] border border-[#ebebeb] text-[#666] transition-all pl-[25px] focus:outline-0 focus:shadow-none focus:border-[#c0b596] focus:bg-transparent"
          value={forms.phone}
          type="phone"
          name="phone"
          onBlur={(e) => changeHandler(e)}
          onChange={(e) => changeHandler(e)}
          required
          placeholder="Telefone"
          disabled={isLoading}
        />
        {errors.phone && (
          <div className="text-red-500 mt-2 text-sm">{errors.phone}</div>
        )}
      </div>
      <div className="col-span-6 md:col-span-6 sm:col-span-12 float-left mx-[15px] mb-[10px] col:float-none col:w-[calc(100%-25px)]">
        <select
          className="form-control w-full bg-transparent h-[50px] border border-[#ebebeb] text-[#666] transition-all pl-[25px] focus:outline-0 focus:shadow-none focus:border-[#c0b596] focus:bg-transparent"
          onBlur={(e) => changeHandler(e)}
          onChange={(e) => changeHandler(e)}
          value={forms.subject}
          type="text"
          name="subject"
          required
          disabled={isLoading}
        >
          <option value="Área Jurídica" disabled>
            Área Jurídica
          </option>
          <option value="LGPD">LGPD</option>
          <option value="Cível">Cível</option>
          <option value="Contratual e Societário">
            Contratual e Societário
          </option>
          <option value="Família e Sucessões">Família e Sucessões</option>
          <option value="Consumidor">Consumidor</option>
          <option value="Imobiliário">Imobiliário</option>
          <option value="Trabalhista">Trabalhista</option>
          <option value="Outro">Outro</option>
        </select>
        {errors.subject && (
          <div className="text-red-500 mt-2 text-sm">{errors.subject}</div>
        )}
      </div>
      <div className="col-span-12 md:col-span-12 sm:col-span-12 mb-[25px] mx-[15px]">
        <textarea
          className="form-control  w-full bg-transparent h-[180px] border border-[#ebebeb] text-[#666] transition-all pt-[15px] pl-[25px] focus:outline-0 focus:shadow-none focus:border-[#c0b596] focus:bg-transparent"
          onBlur={(e) => changeHandler(e)}
          onChange={(e) => changeHandler(e)}
          value={forms.message}
          type="text"
          name="message"
          placeholder="Mensagem"
          required
          style={{
            resize: "none",
          }}
          disabled={isLoading}
        ></textarea>
        {errors.message && (
          <div className="text-red-500 mt-2 text-sm">{errors.message}</div>
        )}
      </div>
      <div className="col-span-12 md:col-span-12 sm:col-span-12 text-center w-full mb-[10px]">
        <button
          type="submit"
          className="bg-[#c0b596] text-[#fff] inline-block py-[12px] px-[22px] border border-[#666] rounded-[55px] transition-all hover:bg-[#666] hover:border-transparent"
        >
          {isLoading ? (
            <CircularProgress size={16} color="inherit" />
          ) : (
            "Enviar mensagem"
          )}
        </button>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </form>
  );
};

export default ContactForm;
