import React from 'react'
import pImg from '../../images/breadcumb/1.jpg'

const PageTitle = (props) => {
    return (
        <section className="page-title min-h-[350px] sm:min-h-[300px] relative flex justify-start  items-center
         z-30  bg-no-repeat bg-center bg-cover text-left" style={{ backgroundImage: `url(${pImg})` }}>
            <div className="wraper">
                <div className="w-[100%] ml-auto text-left pt-[130px] md:pt-[0] sm:pt-[0]">
                    <h2 className="text-[60px]  text-white leading-[60px] mt-[-10px] mb-[20px] font-black sm:text-[30px] sm:leading-[35px] sm:mb-[10px]">{props.pageTitle}</h2>
                </div>
            </div>
        </section>
    )
}

export default PageTitle;