import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle/PageTitle'
import Navbar from '../../components/Navbar/Navbar';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Notfoundpage from '../../components/Notfoundpage/Notfoundpage';

const NotFoundPage =() => {
    return(
        <Fragment>
            <Navbar/>
            <PageTitle pageTitle={'Página não encontrada'} /> 
            <Notfoundpage/>
            <Scrollbar/>
        </Fragment>
    )
};
export default NotFoundPage;

