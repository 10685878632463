import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle/PageTitle'
import About from '../../components/about/about';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar'

const AboutPage =() => {
    return(
        <Fragment>
            <Navbar />
            <PageTitle pageTitle={'Sobre'} /> 
            <About />
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default AboutPage;

