import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar'
import Hero from '../../components/hero/hero';
import Features from '../../components/Features/Features';
import Scrollbar from '../../components/scrollbar/scrollbar';
import PracticeHome from '../../components/PracticeHome';
import ConsultingArea from '../../components/ConsultingArea/ConsultingArea';
import Footer from '../../components/footer/Footer';
import LgpdSection from '../../components/LgpdSection';

const HomePage = () => {
    return (
        <Fragment>
            <Navbar/>
            <Hero />
            <Features />
            <LgpdSection />
            <PracticeHome />
            <ConsultingArea />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default HomePage;