import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";

import Homepage from '../HomePage/HomePage'
import AboutPage from '../AboutPage/AboutPage';
import PracticeSinglePage from '../PracticeSinglePage/PracticeSinglePage';
import PracticePage from '../PracticePage/PracticePage';
import ContactPage from '../ContactPage/ContactPage';
import NotFoundPage from '../NotFound/NotFoundPage';
import PrivacyPolicyPage from '../PrivacyPolicyPage/PrivacyPolicyPage';


const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/home" element={<Homepage />} />
          <Route path="/sobre" element={<AboutPage />} />
          <Route path="/areas-de-atuacao" element={<PracticePage />} />
          <Route path="/area-de-atuacao/:slug" element={<PracticeSinglePage />} />
          <Route path='/contato' element={<ContactPage />} />
          <Route path='/politica-de-privacidade' element={<PrivacyPolicyPage />} />
          <Route path='/*' element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
