import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/logo.png";
import Practices from "../../api/Practices";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const Footer = (props) => {
  const redirectToLinkedin = (e) => {
    e.preventDefault();
    window.open(
      "https://www.linkedin.com/in/julia-ferrareto-7117521b4/",
      "_blank"
    );
  };

  const redirectToInstagram = (e) => {
    e.preventDefault();
    window.open(
      "https://www.instagram.com/juliaferrareto/",
      "_blank"
    );
  };

  const currentYear = new Date().getFullYear();

  return (
    <footer className="relative bg-[#151a30] z-10">
      <div className="pt-[100px] pb-[90px] md:py-[90px] md:pb-[20px] overflow-hidden relative -z-10">
        <div className="wraper">
          <div className="grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-3">
            <div className="w-[420px] xl:w-[355px] lg:w-[297px] pr-[125px] md:mb-[40px] lg:pr-0 relative text-left">
              <div className="mb-7">
                <Link
                  className="text-[45px] font-bold flex items-center text-white"
                  to="/"
                >
                  <img src={Logo} alt="" />
                </Link>
              </div>
              <p className="text-white text-[16px] mb-[10px]">
              Conecte-se conosco através das redes sociais e fique por dentro do que estamos compartilhando.

              </p>
              <ul className="overflow-hidden pt-[15px]">
                <li className="text-white float-left group ml-[15px]">
                  <Link
                    target="_blank"
                    className="text-white transition-all group-hover:text-[#c0b596]"
                    onClick={redirectToLinkedin}
                    to={'/'}
                  >
                    <i className="ti-linkedin"></i>
                  </Link>
                </li>
                <li className="text-white float-left group ml-[15px]">
                  <Link
                    className="text-white transition-all group-hover:text-[#c0b596]"
                    onClick={redirectToInstagram}
                    to="/"
                  >
                    <i className="ti-instagram"></i>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="w-[200px] md:w-full ml-auto md:mb-[40px] lg:pr-0 relative text-left ">
              <div className="mb-7">
                <h3 className="text-[28px] font-medium  font-heading-font text-white capitalize">
                  Links Rápidos
                </h3>
              </div>
              <ul>
                <li className="relative mb-[8px] block">
                  <Link
                    className=" text-white hover:text-[#c0b596] transition-all"
                    to="/"
                  >
                    Home
                  </Link>
                </li>
                <li className="relative mb-[8px] block">
                  <Link
                    className=" text-white hover:text-[#c0b596] transition-all"
                    to="/areas-de-atuacao"
                  >
                    Áreas de Atuação
                  </Link>
                </li>
                <li className="relative mb-[8px] block">
                  <Link
                    className=" text-white hover:text-[#c0b596] transition-all"
                    to="/sobre"
                  >
                    Sobre
                  </Link>
                </li>
                <li className="relative mb-[8px] block">
                  <Link
                    className=" text-white hover:text-[#c0b596] transition-all"
                    to="/Contato"
                  >
                    Contato
                  </Link>
                </li>
              </ul>
            </div>
            <div className="pl-[15px] md:pl-[0px] md:mb-[40px] lg:pr-0 relative text-left ">
              <div className="mb-7">
                <h3 className="text-[28px] font-medium  font-heading-font text-white capitalize">
                  Áreas de Atuação
                </h3>
              </div>
              <ul>
                {Practices.map((practice, Pitem) => (
                  <li className="relative mb-[8px] block" key={Pitem}>
                    <Link
                      className="text-white hover:text-[#c0b596] transition-all"
                      onClick={ClickHandler}
                      to={`/area-de-atuacao/${practice.slug}`}
                    >
                      {practice.sTitle}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className=" md:mb-[40px] lg:pr-0 relative text-left ">
              <div className="mb-7">
                <h3 className="text-[28px] font-medium  font-heading-font text-white capitalize">
                  Horários
                </h3>
              </div>
              <ul>
                <li className="relative mb-[8px] block text-white">
                  Segunda - Sexta: 9:00 - 18:00
                </li>
                <li className="relative mb-[8px] block text-white">
                  Sábado: 9:00 - 13:00
                </li>
                <li className="relative mb-[8px] block text-white">
                  Domingo: Fechado
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="wraper">
        <div className=" border-t-1 border-[rgba(192,181,150,.3)] relative">
          <div className="h-[1px] absolute left-[15px] top-0 bg-[#ffffff0d] w-[calc(100%+30px)]"></div>
          <p className="text-center text-white text-[14px] py-[20px]">
            {" "}
            <a href="/politica-de-privacidade" alt="Link para Política de Privacidade" style={{
              textDecoration: 'underline'
            }}>Política de Privacidade</a> | &copy; {currentYear} Todos os direitos
            reservados
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
