import simg from "../images/practice/civil.png";
import simg2 from "../images/practice/contratual.png";
import simg3 from "../images/practice/familia.png";
import simg4 from "../images/practice/consumidor.png";
import simg5 from "../images/practice/imobiliario.png";
import simg6 from "../images/practice/trabalhista.png";
import simg7 from "../images/practice/lgpd.png";

const Practices = [
  {
    Id: "1",
    sImg: simg,
    sTitle: "Cível",
    subtitle:
      "O Direito Civil pode ser entendido como o “direito do cidadão”. Dessa forma, é um ramo do direito privado, que tem como objetivo determinar as regras e condutas que pessoas físicas e jurídicas devem ter em sociedade.",
    slug: "civel",
    description:
      "Área do Direito Privado que trata das relações entre os cidadãos no âmbito particular. Envolve obrigações, contratos, responsabilidade civil, coisas e demais situações cotidianas.",
    des2: "O Direito Civil, dentro do ordenamento jurídico brasileiro, é o ramo do direito que lida com as relações jurídicas, como os direitos e as obrigações, de pessoas físicas e jurídicas dentro da esfera civil.",
    des3: "Se a descrição parece abrangente, é porque de fato ela é. O Direito Civil é provavelmente o ramo mais amplo do estudo e aplicação do direito dentro do território nacional, ditando os regramentos das relações de pessoas nas questões patrimoniais, obrigacionais e familiares, por exemplo.",
    des4: "O Direito Civil, portanto, pode ser traduzido e entendido com o “direito do cidadão”. Dessa forma, é um ramo do direito privado, que tem como objetivo implicar quais serão as regras e condutas que pessoas físicas e jurídicas devem ter em sociedade.",
    icon: "user-injured",
  },
  {
    Id: "2",
    sImg: simg2,
    sTitle: "Contratual e Societário",
    subtitle:
      "Abrange temas como litígios de sócios, gestão, formação, funcionamento e extinção de empresas, além de responsabilidade civil, contratual e empresarial.",
    slug: "contratual-e-societario",
    description:
      "Abrange temas como litígios de sócios, gestão, formação, funcionamento e extinção de empresas, além de responsabilidade civil, contratual e empresarial.",
    des2: "A Divisão de Assessoria Jurídica Empresarial e Contratual atua de forma proativa como um verdadeiro parceiro do cliente nas transações e na implementação jurídica dos projetos, resultando em uma compreensão mais ampla e no suporte essencial para alcançar os objetivos desejados pelo cliente sem assumir riscos desconhecidos ou desnecessários.",
    des3: "Os pareceres especializados, a orientação jurídica durante as negociações para a celebração de contratos empresariais, estabelecimento e organização de sociedades, redação de minutas de atos societários e suporte paralegal, com registro nos órgãos competentes, propriedade intelectual e operações financeiras, são elaborados com ênfase no negócio conduzido pelo cliente, considerando os diversos aspectos da atividade econômica por ele desenvolvida.",
    des4: "Os profissionais desta Divisão elaboram as respostas às solicitações dos clientes após uma análise cuidadosa em linguagem de fácil compreensão e com a rapidez exigida pelo mundo moderno, atendendo às suas necessidades em tempo hábil.",
    icon: "fa-people-group",
  },

  {
    Id: "3",
    sImg: simg3,
    sTitle: "Família e Sucessões",
    subtitle:
      "Ramo do Direito Civil que disciplina o instituto do casamento e sua dissolução, a tutela e curatela, bem como os direitos pessoais e patrimoniais decorrentes da sociedade conjugal, relações de parentesco e morte.",
    slug: "familia-e-sucessoes",
    description:
      "Área do Direito responsável por regulamentar e intermediar as relações familiares, bem como as questões patrimoniais, testamentárias e sucessórias.",
    des2: "Basicamente o Direito de Família e Sucessões é o sub-ramo do Direito Civil que estuda/diz as implicações jurídicas relacionadas com o casamento, união estável, parentesco, filiação, alimentos, bem de família, tutela, curatela e guarda.",
    des3: 'Além disso, atualmente o Direito de Família e Sucessões vem estuando e situando as novas interpretações de famílias, ou seja, vem estudando e trazendo novos conceitos a respeito das chamadas "novas famílias".',
    icon: "flaticon-house",
  },
  {
    Id: "4",
    sImg: simg4,
    sTitle: "Consumidor",
    subtitle:
      "O Direito do Consumidor é o ramo do direito que lida com as relações jurídicas entre fornecedores de bens e serviços e seus consumidores",
    slug: "consumidor",
    description:
      "Área do Direito que tem como objetivo disciplinar relações de consumo. Abrange responsabilidade do produto, revisão contratual, além de atuação consultiva e extrajudicial.",
    des2: "Ou seja, o direito do consumidor é a soma de regras e princípios jurídicos que envolvem todas as relações de consumo, isto é, as relações entre o consumidor e o fornecedor de produtos e/ou de serviços.",
    des3: "Tem por objetivo assegurar que os consumidores obtenham acesso a informações quanto a origem e qualidade dos produtos e serviços; assegurar proteção contra fraudes no mercado de consumo; garantir transparência a segurança para os usuários dos bens e serviços e harmonizar as relações consumo por meio da intervenção jurisdicional.",
    des4: "O direito do consumidor assegura que o consumidor possa recorrer ao judiciário para a prevenção e reparação de danos patrimoniais decorrentes na falha no fornecimento de bens e prestação de serviços para o consumidor final.",
    icon: "flaticon-thief",
  },
  {
    Id: "5",
    sImg: simg5,
    sTitle: "Imobiliário",
    subtitle:
      "Embora tenha um objeto de estudo bem definido, trabalha com diferentes campos do direito, como o direito contratual, do consumidor, da família, civil, entre outros.",
    slug: "imobiliario",
    description:
      "Ramo do Direito que lida com as relações jurídicas que envolvam a posse, compra, venda, doação, sucessão, troca e propriedade de bens imóveis.",
    des2: "O direito imobiliário é um ramo do direito privado que lida, como o nome já aponta, que estuda e lida com as relações jurídicas e de direito que envolvam a posse, compra, venda, doação, sucessão, troca e propriedade de bens imóveis, inclusive a incorporação imobiliária.",
    des3: "Trata-se de uma área que sempre apresentará relevância social, uma vez que a circulação de imóveis entre diferentes pessoas é inevitável na sociedade em que vivemos.",
    des4: "Dessa forma, o direito imobiliário é uma área que permite a atuação do profissional do direito em diferentes níveis, se apresentando como um ramo do direito bastante frutífero e interessante para advogados que procuram se especializar nas relações jurídicas envolvendo bens imóveis.",
    icon: "flaticon-house",
  },
  {
    Id: "6",
    sImg: simg6,
    sTitle: "Trabalhista",
    subtitle:
      "A Consolidação das Leis do Trabalho (CLT) e a Constituição Federal de 1988 regem as normas e regras que estabelecem os critérios mínimos para que essa relação seja harmoniosa e lícita, preservando os direitos de ambas as partes e a dignidade humana do trabalhador.",
    slug: "trabalhista",
    description:
      "Área do Direito Privado responsável por regulamentar as relações jurídicas entre empregadores e empregados, estabelecendo normas e garantindo direitos no contexto do trabalho.",
    des2: "Direito do trabalho é o ramo jurídico que estuda as relações de trabalho. Esse direito é composto de conjuntos de normas, princípios e outras fontes jurídicas que regem as relações de trabalho, regulamentando a condição jurídica dos trabalhadores.",
    des3: "Surge como autêntica expressão do humanismo jurídico e instrumento de renovação social. Constitui atitude de intervenção jurídica em busca de um melhor relacionamento entre a pessoa que trabalha e aquelas para as quais o trabalho se destina. Visa também a estabelecer uma plataforma de direitos básicos. Portanto, a definição de direito do trabalho é o conjunto de normas e princípios que regulamentam o relacionamento entre empregado e empregadores.",
    icon: "flaticon-employee",
  },
  {
    Id: "7",
    sImg: simg7,
    sTitle: "LGPD",
    slug: "lgpd",
    subtitle:
      'A nomeação de um DPO (Data Protection Officer) não precisa ser uma dor de cabeça para a sua empresa. Com o serviço de "DPO as a Service", você contrata um especialista em privacidade de dados para ocupar a função, garantindo conformidade com um ponto fundamental da Lei Geral de Proteção de Dados.',
    description:
      'A nomeação de um DPO (Data Protection Officer) não precisa ser uma dor de cabeça para a sua empresa. Com o serviço de "DPO as a Service", você contrata um especialista em privacidade de dados para ocupar a função, garantindo conformidade com um ponto fundamental da Lei Geral de Proteção de Dados.',
    des2: "A Lei Geral de Proteção de Dados Pessoais (LGPD) estabelece diretrizes importantes e obrigatórias para a coleta, processamento e armazenamento de dados pessoais. Ela foi inspirada na GDPR (General Data Protection Regulation), que entrou em vigência em 2018 na União Europeia, trazendo grandes impactos para empresas e consumidores.",
    des3: "No Brasil, a LGPD (Lei nº 13.709, de 14/8/2018) entrou em vigor em 18 de setembro de 2020, representando um passo importante para o Brasil. Com isso, passamos a fazer parte de um grupo de países que contam com uma legislação específica para a proteção de dados dos seus cidadãos. Diante dos atuais casos de uso indevido, comercialização e vazamento de dados, as novas regras garantem a privacidade dos brasileiros, além de evitar entraves comerciais com outros países.",
    icon: "flaticon-employee",
  },
];
export default Practices;
