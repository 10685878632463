import React from "react";
import { Link } from "react-router-dom";
import MobileMenu from "../MobileMenu/MobileMenu";
import Logo from "../../images/logo.png";

const Header = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <header className="absolute left-0 top-0 w-full z-[111] lg:relative">
      <div
        className="wpo-site-header"
        style={{
          background: "#272c3f",
        }}
      >
        <div className="wraper">
          <div className="flex items-center justify-between ">
            <MobileMenu />

            <div className="logo w-[255px] md:w-[200px] md:mx-auto sm:w-[180px] col:w-[160px]">
              <Link
                className="text-[45px] col:text-[25px] font-bold flex items-center text-white"
                to="/"
              >
                <img className="w-full" src={Logo} alt="" />
              </Link>
            </div>
            <ul className="md:hidden mr-[-50px] lg-[-10px]">
              <li className="relative inline-block">
                <Link
                  onClick={ClickHandler}
                  to="/"
                  className="relative group text-[15px] lg:text-[14px] py-[35px] xl:py-[30px] px-[18px] xl:px-[6px] text-white block uppercase font-base-font font-normal hover:text-[#c0b596] transition-all
                               "
                >
                  Home
                </Link>
              </li>
              {/* to="/area-de-atuacao/Family-Law" */}
              <li className="relative inline-block">
                <Link
                  onClick={ClickHandler}
                  to="/areas-de-atuacao"
                  className="relative group text-[15px] lg:text-[14px] py-[35px] xl:py-[30px] px-[18px] xl:px-[6px] text-white block uppercase font-base-font font-normal hover:text-[#c0b596] transition-all
                               "
                >
                  Áreas de Atuação
                </Link>
              </li>
              <li className="relative inline-block">
                <Link
                  onClick={ClickHandler}
                  to="/sobre"
                  className="relative group text-[15px] lg:text-[14px] py-[35px] xl:py-[30px] px-[18px] xl:px-[6px] text-white block uppercase font-base-font font-normal hover:text-[#c0b596] transition-all
                               "
                >
                  Sobre
                </Link>
              </li>
              <li className="relative inline-block">
                <Link
                  onClick={ClickHandler}
                  to="/contato"
                  className="relative group text-[15px] lg:text-[14px] py-[35px] xl:py-[30px] px-[18px] xl:px-[6px] text-white block uppercase font-base-font font-normal hover:text-[#c0b596] transition-all
                               "
                >
                  Contato
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
