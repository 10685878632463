import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import "./style.css";

const Scrollbar = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    // Verifique a posição de rolagem e atualize a visibilidade do botão
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    setIsVisible(scrollTop > 200);
  };

  // Adiciona um ouvinte de rolagem quando o componente monta
  useEffect(() => {
    // Rola para o topo ao abrir a página
    window.scrollTo(0, 0);
    
    window.addEventListener("scroll", handleScroll);

    // Remove o ouvinte de rolagem quando o componente desmonta
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`col-lg-12`}>
      <div className="header-menu">
        <ul className="smothscroll">
          <li>
            {isVisible && (
              <AnchorLink href="#scrool">
                <i className="ti-arrow-up"></i>
              </AnchorLink>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Scrollbar;
