import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import {Link} from "react-router-dom";
import abImg from "../../images/lgpd/lgpd.webp";

const LgpdSection = (props) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <section className="pt-[120px] pb-[100px] md:py-[80px]">
      <div className="wraper">
        <div className="grid grid-cols-12 items-center gap-3">
          <div className="col-span-6 md:col-span-12">
            <div className="mb-[20px]">
              <div className="text-left sm:text-center">
                <span className="text-[16px] text-[#ada282]">
                  Somos Especialistas
                </span>
                <h2
                  className="text-[36px] md:text-[26px] font-medium text-[#333] pb-[20px] relative 
                        before:absolute before:content-[''] before:left-[-68px] 
                        before:top-[30px] before:transform before:-translate-y-1/2 
                        before:w-[60px] before:h-[1px] before:bg-[#c0b596] md:before:hidden"
                >
                Por que um DPO é essencial.
                </h2>
              </div>
              <p className="text-[#666] text-[15px] leading-[28px] mb-[30px]">
                A nomeação de um DPO (Data Protection Officer) não precisa ser
                uma dor de cabeça para a sua empresa. Com o serviço de "DPO as a
                Service", você contrata um especialista em privacidade de dados
                para ocupar a função, garantindo conformidade com um ponto
                fundamental da Lei Geral de Proteção de Dados.
              </p>
              <p className="text-[#666] text-[15px] leading-[28px] mb-[30px]">
                {" "}
                Seu time se concentra nas suas atividades diárias e você não
                precisa se preocupar em escolher e treinar alguém da equipe para
                a função.
              </p>
              <div className="mb-[50px] col:mb-[20px]">
                <Link
                  to="/area-de-atuacao/lgpd"
                  className="bg-[#c0b596] cursor-pointer text-[16px] font-semibold text-white px-[38px] py-[10px] inline-block mt-[6px] transition ease-in-out duration-300 hover:bg-[#d4c291]
                        col:mb-[5px] col:mt-[15px] col:text-[15px] col:px-[18px] col:py-[8px] 
                        "
                >
                  Saiba mais
                </Link>
              </div>
            </div>
          </div>
          <div className="col-span-6 md:col-span-12">
            <div className="relative mb-[20px]">
              <div
                className="relative  max-w-[520px] ml-auto before:absolute
                     before:w-full before:h-full before:content-[''] before:-z-30 before:right-[-10px] before:bottom-[-10px] "
              >
                <div
                  className="relative after:absolute after:content-[''] 
                        after:z-30 after:w-full after:h-full after:left-0 after:top-0"
                >
                  <img className="w-full" src={abImg} alt="Imagem de LGPD" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="7Jv48RQ_2gk"
        onClose={() => setOpen(false)}
      />
    </section>
  );
};

export default LgpdSection;
