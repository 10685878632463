import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle/PageTitle'
import Practice from '../../components/Practice/Practice';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar'

const PracticePage =() => {

    return(
        <Fragment>
            <Navbar />
            <PageTitle pageTitle={'Áreas de Atuação'}/> 
            <Practice />
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default PracticePage;

