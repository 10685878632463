import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Footer from '../../components/footer/Footer';
import Practices from '../../api/Practices';
import Sidebar from './Sidebar';

const PracticeSinglePage = (props) => {
    const { slug } = useParams()

    const PracticeDetails = Practices.find(item => item.slug === slug)

    return (
        <Fragment>
            <Navbar />
            <PageTitle pageTitle={PracticeDetails.sTitle} pagesub={'Practice'} />
            <div className="py-[100px] md:py-[80px]">
                <div className="wraper">
                    <div className="grid grid-cols-12 gap-3">
                        <Sidebar />
                        <div className="col-span-8 md:col-span-12 order-2 md:order-1">
                            <img className="w-full" src={PracticeDetails.sImg} alt="" />
                            <div className="pt-[50px]">
                                <h2 className="text-[36px] text-[#282e3f] mb-[25px]">{PracticeDetails.sTitle}</h2>
                                <p className="text-[16px] leading-[30px] py-[20px] mb-[16px]">{PracticeDetails.subtitle} </p>
                                <p className="text-[16px] leading-[30px] py-[20px] mb-[16px]">{PracticeDetails.des2} </p>
                                <p className="text-[16px] leading-[30px] mb-[25px]">{PracticeDetails.des3} </p>
                                {PracticeDetails.des4 && <p className="text-[16px] leading-[30px] mb-[25px]">{PracticeDetails.des4} </p>}
                            </div>
                            {/* <div className="mt-[40px]">
                                <div className="float-left col:float-none mr-[30px] col:mr-0 col:mb-[30px] w-[30%] col:w-full">
                                    <img src={pImg} alt="" />
                                </div>
                                <div className="organigation-text float-left w-[64%] col:float-none  col:w-full">
                                    <h2 className="text-[24px] text-[#282e3f] mb-[8px] block">{PracticeDetails.sTitle} Organizations</h2>
                                    <span className="block pt-[15px] text-[15px] text-[#333]"><i className="fa fa-check-square-o text-[#c0b596] pr-[15px]" aria-hidden="true"></i>The master-builder of human happiness.</span>
                                    <span className="block pt-[15px] text-[15px] text-[#333]"><i className="fa fa-check-square-o text-[#c0b596] pr-[15px]" aria-hidden="true"></i>Occasionally circumstances occur in which toil and pain</span>
                                    <span className="block pt-[15px] text-[15px] text-[#333]"><i className="fa fa-check-square-o text-[#c0b596] pr-[15px]" aria-hidden="true"></i>Avoids pleasure itself, because it is pleasure</span>
                                    <span className="block pt-[15px] text-[15px] text-[#333]"><i className="fa fa-check-square-o text-[#c0b596] pr-[15px]" aria-hidden="true"></i>who do not know how to pursue pleasure</span>
                                    <span className="block pt-[15px] text-[15px] text-[#333]"><i className="fa fa-check-square-o text-[#c0b596] pr-[15px]" aria-hidden="true"></i>To take a trivial example, which of us ever undertakes</span>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default PracticeSinglePage;
