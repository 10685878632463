import React from "react";
import cImg from "../../images/contact/1.jpg";
import { Link } from "react-router-dom";

const ConsultingArea = (props) => {
  return (
    <section
      className="relative bg-center bg-cover bg-no-repeat bg-fixed py-[100px] z-10 
   before:content-[''] before:bg-[rgba(21,26,48,0.9)] before:w-full before:h-full before:absolute before:left-0 before:-z-10 before:top-0"
      style={{ backgroundImage: `url(${cImg})` }}
    >
      <div className="wraper">
        <div className="grid grid-cols-12 gap-3">
          <div className="col-span-12 md:col-span-12 ">
            <div className="contact-text md:mb-[30px]">
              <div className="title">
                <span className="text-[16px] text-[#ada282]">
                  Nos envie suas perguntas
                </span>
                <h2
                  className="text-[36px] md:text-[26px] font-medium text-white pb-[20px] md:pb-[10px] relative 
                        before:absolute before:content-[''] before:left-[-68px] 
                        before:top-[30px] before:transform before:-translate-y-1/2 
                        before:w-[60px] before:h-[1px] before:bg-[#c0b596] md:before:hidden"
                >
                  Alguma dúvida?
                </h2>
              </div>
              <p className="mt-[30px] text-[#ddd] leading-[30px] text-[16px]">
                Se você tiver alguma dúvida ou precisar de mais informações, não
                hesite em entrar em contato conosco. Estamos aqui para ajudar e
                fornecer a assistência jurídica necessária para garantir a melhor
                experiência possível.
              </p>

              <Link
                to="/contato"
                className="bg-[#c0b596] cursor-pointer text-[16px] font-semibold text-white px-[38px] py-[10px] inline-block mt-[30px] transition ease-in-out duration-300 hover:bg-[#d4c291]
                        col:mb-[5px] col:mt-[15px] col:text-[15px] col:px-[18px] col:py-[8px] 
                        "
              >
                Entrar em contato
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConsultingArea;
