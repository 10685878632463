import React from "react";
import { Link } from "react-router-dom";
import Practices from "../../api/Practices";

const Practice = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section className="bg-[#f5f5f5] pt-[95px] pb-[70px]">
      <div className="wraper">
        <div className="col-span-12 text-center">
          <div className="mb-[60px]">
            <span className="text-[16px] text-[#ada282]">Áreas de Atuação</span>
            <h2
              className=" text-[36px] md:text-[26px] font-medium text-[#333] pb-[20px] relative before:absolute before:content-['']
             before:left-[50%] before:bottom-0 before:transform before:-translate-x-1/2 before:w-[60px] before:h-[3px]
              before:bg-[#c0b596]"
            >
              Como podemos te ajudar
            </h2>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-3">
          {Practices.map((practice, Pitem) => {
            const blockClass =
              practice.Id === "7"
                ? "col-span-12 md:col-span-12 sm:col-span-12 mb-5 flex flex-col"
                : "col-span-4 md:col-span-6 sm:col-span-12 mb-5 flex flex-col";

            return (
              <div className={blockClass} key={Pitem}>
                <Link
                  onClick={ClickHandler}
                  to={`/area-de-atuacao/${practice.slug}`}
                  className="h-full"
                >
                  <div className="group text-center py-[40px] border border-[#c0b596] flex flex-col h-full">
                    <div className="px-[50px]">
                      <h3
                        className="text-[#373737] text-[24px] col:text-[20px] font-medium relative  pt-[24px] mb-[10px] hover:text-[#c0b596] transition-all
                        before:absolute before:content-[''] before:left-1/2 before:top-[10px] before:transform before:-translate-x-1/2 before:w-[65px] before:h-[1px] before:bg-[#c0b596]"
                      >
                        {practice.sTitle}
                      </h3>
                      <p className="text-[#777] text-[16px] font-normal">
                        {practice.description}
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Practice;
